import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

const ImageBlock = ({ images }) => {
	const imageData = images.map((image, i) => ({
		gatsbyImage: images[i].image?.localFile.childImageSharp.gatsbyImageData,
		altText: images[i].image?.altText,
	}))

	return (
		<div className="my-8 md:my-[4.5rem]">
			{imageData && imageData.length === 1 ? (
				<GatsbyImage
					image={imageData[0].gatsbyImage}
					className="max-lg:h-[50vh] w-full"
					objectFit="cover"
					alt={imageData[0].altText}
				/>
			) : (
				<div className="mx-auto lg:flex justify-between">
					<GatsbyImage
						className="max-lg:mb-8 lg:inline-block w-full lg:mr-4 lg:w-1/2"
						image={imageData[0].gatsbyImage}
						objectFit="cover"
						alt={imageData[0].altText}
					/>
					<GatsbyImage
						className="lg:inline-block w-full lg:ml-4 lg:w-1/2"
						image={imageData[1].gatsbyImage}
						objectFit="cover"
						alt={imageData[1].altText}
					/>
				</div>
			)}{' '}
		</div>
	)
}

export default ImageBlock
