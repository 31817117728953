import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import MainSection from './postBlocks/mainSection'
import ImageBlock from './postBlocks/imageBlock'
import QuoteBlock from './postBlocks/quoteBlock'
import FeaturedProjects from './page_blocks/featuredProjects'

const Post = ({
	post: {
		title,
		featuredImage,
		additionalPostFields,
		pageSections,
		services,
		id,
	},
}) => {
	const backgroundColor = additionalPostFields.brandColor
		? hexToRGB(additionalPostFields.brandColor)
		: '#ebf2f6'

	const imageData = {
		gatsbyImage:
			featuredImage?.node?.localFile?.childImageSharp.gatsbyImageData,
		altText: featuredImage?.node?.altText || title,
	}
	let mainCount = 0
	const styledPageSections = pageSections.sections?.map((section, index) => {
		switch (section.fieldGroupName) {
			case 'Post_Pagesections_Sections_MainSection':
				mainCount++
				return (
					<MainSection section={section} mainCount={mainCount} key={index} />
				)
			case 'Post_Pagesections_Sections_ImageBlock':
				return <ImageBlock images={section.images} key={index} />
			case 'Post_Pagesections_Sections_ClientQuote':
				return <QuoteBlock section={section} key={index} />
			default:
				return null
		}
	})

	const serviceTags = services.nodes?.map(tag => (
		<li className="text-sm md:text-md pl-2 inline list-item pb-2">
			{tag.name}
		</li>
	))

	function hexToRGB(hexColor) {
		const r = parseInt(hexColor.slice(1, 3), 16)
		const g = parseInt(hexColor.slice(3, 5), 16)
		const b = parseInt(hexColor.slice(5, 7), 16)
		return `rgba(${r},${g},${b},0.17)`
	}

	return (
		<>
			<article className="our-work-post">
				<div
					className="relative z-0"
					style={{
						background: `linear-gradient(158.44deg, rgba(0, 0, 0, 0.05) 5.69%, ${backgroundColor} 63.1%)`,
					}}
					id="post-header"
				>
					<div className="section-wide md:pt-40 pb-0">
						<div className="mx-auto pb-8 sm:pb-20">
							{additionalPostFields.label ? (
								<div>
									<div className="sm:inline-block sm:w-fit sm:max-w-4/5 md:w-4/5 sm:mr-6">
										<span className="block text-lg md:text-[1.5rem] pb-3 md:pb-5 lg:pb-8 font-medium inline-block">
											{additionalPostFields.label}
										</span>
										<h1 className="max-w-4xl pb-12">{title}</h1>
									</div>

									{serviceTags.length ? (
										<div className="inline-block w-fit align-top ">
											<span className="font-mono tracking-wider uppercase text-xs md:text-md">
												Services:
											</span>
											<ul className="list-disc list-inside pt-2 leading-5">
												{serviceTags}
											</ul>
										</div>
									) : (
										''
									)}
								</div>
							) : (
								<div>
									<div className="sm:inline-block sm:w-fit sm:max-w-4/5 md:w-4/5 sm:mr-6">
										<h1 className="h1 max-w-4xl pt-0 pb-12">{title}</h1>
									</div>
									{serviceTags.length ? (
										<div className="inline-block w-fit align-top">
											<span className="font-mono tracking-wider uppercase text-xs md:text-md">
												Services:
											</span>
											<ul className="list-disc list-inside pt-2">
												{serviceTags}
											</ul>
										</div>
									) : (
										''
									)}
								</div>
							)}
						</div>
					</div>
					<div id="featured-image" className="relative mb-[4.5rem]">
						<GatsbyImage
							loading="eager"
							className="max-lg:h-[60vh] lg:mx-6"
							priority="true"
							image={imageData.gatsbyImage}
							objectFit="cover"
							alt={imageData.altText}
						/>
					</div>
				</div>

				{styledPageSections}
			</article>
			<FeaturedProjects postPage={id} />
		</>
	)
}

export default Post
