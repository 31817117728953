import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Post from '../components/post'

const PostTemplate = ({ data: { post } }) => {
	return (
		<Layout>
			<Post post={post} />
		</Layout>
	)
}

export default PostTemplate

export function Head({ data: { post } }) {
	return (
		<Seo
			title={post.title}
			description={post.additionalPostFields.seoDescription}
		/>
	)
}

export const pageQuery = graphql`
	query PostById(
		# these variables are passed in via createPage.pageContext in gatsby-node.js
		$id: String!
		$previousPostId: String
		$nextPostId: String
	) {
		# selecting the current post by id
		post: wpPost(id: { eq: $id }) {
			...BasicPostInfo
			...FeaturedImage
			...AdditionalPostFieldsInfo
			...PageSectionInfo
			...ServiceInfo
		}
		# this gets us the previous post by id (if it exists)
		previous: wpPost(id: { eq: $previousPostId }) {
			uri
			title
		}
		# this gets us the next post by id (if it exists)
		next: wpPost(id: { eq: $nextPostId }) {
			uri
			title
		}
	}
`
